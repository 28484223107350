/* Typography */
/* Brand colors */
/* Dark blue */
/* Light blue */
/* Orange */
/* Special cases */
/* TILES FOR HOME AND LANDING PAGES */
/* 3 wide */
#tiles {
  box-sizing: border-box;
  padding: 2rem 0;
  font-size: 0;
  margin: 0; }
  #tiles .tile {
    box-sizing: border-box;
    width: 31.25%;
    margin: 1.5%;
    display: inline-block;
    float: none; }
  #tiles .tile.row-end {
    margin-right: 0; }
  #tiles .tile.row-start {
    margin-left: 0; }
  #tiles .tile.double {
    width: 65%; }
  #tiles .tile {
    font-family: Bitter, serif; }
  #tiles .tile .tile-title-cont,
  #tiles .tile p {
    color: #ffffff;
    padding: 5px 3%;
    min-height: 60px;
    line-height: 1.35em;
    position: absolute;
    left: 0;
    right: 0; }
  #tiles .tile .tile-title-cont {
    background-color: rgba(255, 255, 255, 0.6); }
  #tiles .tile .tile-title-cont.bottom {
    bottom: 28px; }
  #tiles .tile .tile-title-cont.top {
    top: 25px; }
  #tiles .tile img.tile-placeholder {
    display: block; }
  #tiles .tile span.callout {
    font-size: 1.0em;
    line-height: 1em;
    font-weight: 600;
    display: inline-block;
    margin-top: 2px; }
  #tiles .tile a {
    text-decoration: none; }
  #tiles .tile h2 {
    line-height: 1.3;
    font-size: 1.5rem; }
  #tiles .tile h2.glow {
    -moz-text-shadow: 0 0 7px #333;
    -webkit-text-shadow: 0 0 7px #333;
    text-shadow: 0 0 7px #333;
    /* MS */ }
  #tiles .tile h2.blue {
    color: #004068; }
  #tiles .tile.double .cont-img.showable {
    display: none; }

@media screen and (max-width: 1200px) {
  #tiles .tile {
    margin: 0.9%; }
    #tiles .tile.row-end {
      margin: 0.9%; }
    #tiles .tile.row-start {
      margin: 0.9%; } }

@media screen and (max-width: 800px) {
  /* 2 tiles wide */
  #tiles .tile {
    box-sizing: border-box;
    width: 48%;
    margin: 1%;
    vertical-align: top; }
    #tiles .tile.row-start {
      margin-left: 1%; }
    #tiles .tile.row-end {
      margin-right: 1%; }
    #tiles .tile .tile-title-cont,
    #tiles .tile p {
      padding: 2%;
      position: absolute;
      left: 0;
      right: 0;
      box-sizing: border-box;
      margin: 0; }
    #tiles .tile h2 {
      font-size: 1.1rem;
      line-height: 1.2;
      margin: 0; }
    #tiles .tile.double {
      width: 98%; }
    #tiles .tile .tile-title-cont.bottom {
      bottom: 1em;
      min-height: 3rem; } }

@media screen and (max-width: 400px) {
  #tiles .tile,
  #tiles .tile.double,
  #tiles .tile.even,
  #tiles .tile.odd {
    margin: 2% 0;
    width: 100%; } }
