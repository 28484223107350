/* LISTING TEMPLATE*/
/* Typography */
/* Brand colors */
/* Dark blue */
/* Light blue */
/* Orange */
/* Special cases */
body.listing {
  background-color: #ffffff; }

body.listing #content.cont-root {
  margin-top: 50px; }

body.listing #content {
  color: #000000; }

body.listing #content .column.left {
  min-width: 360px;
  width: 38%; }

body.listing #content .column.left.conditions {
  min-width: 0;
  width: 20%; }

body.listing #content .column.right.conditions {
  width: 75%; }

.form-filter.conditions {
  font-weight: 800;
  line-height: 2em; }

body.listing #content .column.left.conditions .form-filter {
  margin-top: 30px; }

body.listing #content .column.left .page-title.noimage {
  position: relative;
  top: 0;
  display: inline-block;
  padding: 13px 10px;
  left: 0;
  font-weight: 400;
  font-size: 1.7em;
  background-color: #004068;
  color: #ffffff;
  z-index: 1;
  line-height: 1.1em; }

div#header-image .page-title.noimage {
  display: none; }

nav.secondary-nav {
  font-weight: 800;
  line-height: 1.5em; }

nav.secondary-nav.lower {
  margin-bottom: 20px; }

nav.secondary-nav.upper {
  display: none;
  width: 96%;
  padding: 0 2%;
  margin-bottom: 30px; }

nav.secondary-nav.upper li {
  display: inline-block; }

nav.secondary-nav.upper span.nav-delimiter {
  margin: 0 10px; }

@media screen and (max-width: 1000px) {
  .form-filter {
    padding-left: 3%; }
  nav.secondary-nav.lower {
    padding: 0 3%;
    width: 94%; } }

@media screen and (max-width: 800px) {
  nav.secondary-nav.upper {
    display: block; }
  nav.secondary-nav.lower {
    display: none; }
  body.listing #content .column.left,
  body.listing #content .column.left.conditions {
    position: relative;
    width: 360px;
    margin: 0 auto;
    float: none; }
  body.listing #content .column.right,
  body.listing #content .column.right.conditions {
    margin-left: 0;
    float: none;
    padding: 0 2%;
    width: 96%;
    margin-bottom: 40px; }
  #content .column.right .form-filter {
    display: block;
    padding-left: 0; }
  #content .column.left .form-filter {
    display: none; }
  body.listing #content .column.left .page-title.noimage {
    display: none; }
  div#header-image .page-title.noimage {
    display: inline-block;
    background-color: #004068;
    color: #ffffff; } }

/* Map */
#map {
  height: 100%; }

@media screen and (max-width: 360px) {
  body.inside #content .column.left {
    width: 290px;
    min-width: 290px; } }
