/* Typography */
/* Brand colors */
/* Dark blue */
/* Light blue */
/* Orange */
/* Special cases */
/* LANDING TREMPLATE */
body.landing {
  background-color: #e5eff4; }
  body.landing #content {
    color: #ffffff; }
  body.landing #ads {
    background-color: #d3e2ea;
    padding: 30px 0; }
  body.landing #ads .heading {
    color: #ee4611;
    font-weight: 800; }
  body.landing #ads .cont-page > .column.left {
    width: 200px; }
  body.landing #ads .cont-page > .column.right {
    width: 750px; }
  body.landing .ad {
    float: right;
    height: 128px;
    width: 360px;
    margin-left: 25px; }
  body.landing .ads.single-column {
    width: 780px;
    margin: 0 auto; }
  body.landing .ads.single-column .ad {
    float: left; }
  body.landing #header-image-caption.copy {
    max-width: 780px;
    margin: 2em auto 0; }
  @media screen and (max-width: 980px) {
    body.landing #ads .cont-page > .column.left {
      padding-left: 8px;
      width: 50%;
      margin-right: 3%; }
    body.landing #ads .cont-page > .column.right {
      width: 37%;
      min-width: 360px; } }
  @media screen and (max-width: 800px) {
    body.landing .ads.single-column {
      width: 100%; }
    body.landing .ads.single-column .ad {
      float: none;
      margin-left: auto;
      margin-right: auto; }
    body.landing #ads .cont-page > .column.left {
      width: 40%; }
    body.landing #ads .cont-page > .column.right {
      width: 37%;
      min-width: 360px; } }
  @media screen and (max-width: 650px) {
    body.landing #ads .cont-page > .column.left {
      margin-right: 0;
      float: none;
      width: 98%;
      padding-left: 1%;
      padding-right: 1%;
      margin-bottom: 40px; }
    body.landing #ads .cont-page > .column.right {
      float: none;
      width: 100%; }
    body.landing .ad {
      float: none;
      margin: 0 auto; } }
  @media screen and (max-width: 360px) {
    body.landing #ads .cont-page > .column.right {
      min-width: 290px; }
    body.landing .ad {
      width: 290px; } }
