/* MAIN RESORT INFO */
/* Typography */
/* Brand colors */
/* Dark blue */
/* Light blue */
/* Orange */
/* Special cases */
body.resort {
  background-color: #e5eff4; }
  body.resort div#header-image #page-title {
    background-color: #e5eff4; }
  body.resort #content {
    background-color: #ffffff;
    margin-top: 30px;
    padding-top: 30px;
    padding-bottom: 50px; }
  body.resort #content nav.column.left {
    min-width: 170px;
    width: 18%;
    padding-right: 3%;
    float: left; }
  body.resort #content section.column.right {
    width: 75%;
    float: right; }
  body.resort #content section.column.right .column.left {
    width: 37%;
    padding-right: 3%; }
  body.resort #content section.column.right .column.right {
    width: 60%; }
  body.resort #resort-social .icon {
    padding: 0 4px;
    margin: 2px; }
  body.resort #instagram {
    margin-top: 25px; }
  body.resort #instagram img {
    max-width: 100%; }
  body.resort .videoicon {
    color: #ffffff;
    position: absolute;
    top: 1em;
    left: 1em; }
  @media screen and (max-width: 835px) {
    body.resort #content section.column.right {
      width: 70%; } }
  body.resort #resort-logo.column.left {
    width: 150px;
    margin-bottom: 20px; }
  body.resort #resort-social {
    text-align: right;
    margin-bottom: 30px; }
  body.resort #resort-heading {
    font-size: 1.7em;
    line-height: 1.0em;
    margin-bottom: 30px;
    margin-top: 0; }
  body.resort #resort-details,
  body.resort #resort-links {
    font-size: 1.1em;
    font-weight: 800;
    line-height: 1.35em;
    margin: 0; }
  body.resort #resort-details .label {
    display: inline-block; }
  body.resort #resort-details {
    margin-bottom: 30px; }
  body.resort #resort-details a.highlight,
  body.resort #resort-links a.highlight {
    color: #ee4611; }
  body.resort .resort-info.column.right {
    font-weight: 600;
    letter-spacing: 0.125px;
    font-size: 1.05em;
    line-height: 1.35em; }
  body.resort .cont-img.trail-map {
    margin-top: 30px; }
  body.resort #resort-nav-heading {
    color: #ee4611; }
  body.resort #resort-nav {
    line-height: 1.1em; }
  body.resort #resort-nav li {
    padding: 4px 0; }
  @media screen and (max-width: 980px) {
    body.resort #content .cont-page,
    body.resort #lodging .cont-page {
      width: 98%;
      padding: 0 1%; }
    body.resort #content nav.column.left {
      padding-left: 8px;
      width: 162px; } }
  @media screen and (max-width: 768px) {
    body.resort #content nav.column.left {
      display: none; }
    body.resort #content section.column.right {
      float: none;
      width: 100%; } }
  @media screen and (max-width: 560px) {
    body.resort #content section.column.right .resort-info.column.left,
    body.resort #content section.column.right .resort-info.column.right {
      float: none;
      padding-right: 0;
      width: 100%; }
    body.resort #content section.column.right .resort-info.column.right {
      margin-top: 30px; }
    body.resort #instagram {
      display: none; } }
  body.resort #lodging {
    background-color: #d3e2ea;
    padding: 50px 0; }
  body.resort #lodging .heading {
    color: #ee4611;
    font-weight: 800; }
  body.resort #lodging .cont-page > .column.left {
    width: 17%;
    margin-right: 3%; }
  body.resort #lodging .cont-page > .column.right {
    width: 80%; }
  body.resort #lodging a.more {
    display: inline-block;
    margin-top: 40px;
    color: #ee4611;
    font-size: 1.2em;
    font-weight: 800; }
  @media screen and (max-width: 560px) {
    body.resort #lodging .cont-page > .column.left {
      width: 100%;
      margin-right: 30%;
      float: none;
      margin-bottom: 30px; }
    body.resort #lodging .cont-page > .column.right {
      width: 100%;
      float: none; }
    body.resort .advertiser {
      width: 100%;
      margin-right: 0;
      max-width: 100%; } }

.advertiser {
  margin-bottom: 25px;
  margin-right: 4%;
  min-height: 145px;
  max-width: 380px; }
  .advertiser .cont-img {
    width: 145px;
    float: left; }
  .advertiser .content {
    margin-left: 155px;
    color: #000000;
    font-size: 0.75em;
    font-weight: 600; }
  .advertiser .content p {
    line-height: 1.4em; }
  .advertiser .content.noimage {
    margin-left: 0; }
  .advertiser .content h5 {
    font-weight: 800;
    margin: 0; }
  .advertiser footer {
    margin-top: 10px;
    font-size: 1.1em; }
  .advertiser .more {
    color: #ee4611;
    font-weight: 800; }
  .advertiser .sponsor {
    font-weight: 300;
    float: right; }
