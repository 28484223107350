div#siteby {
  color: #FFFFFF;
  font-family: 'Open Sans', sans-serif;
  font-size: 10px;
  font-weight: 300;
  letter-spacing: 1px;
  margin-top: 10em;
  padding: 4em;
  text-align: center;
  text-transform: uppercase;
  vertical-align: top; }
  div#siteby a {
    color: #FFFFFF;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase; }
  div#siteby a:hover {
    color: #D22D02; }
  div#siteby img {
    height: 18px;
    width: auto;
    vertical-align: middle; }
