/* Typography */
/* Brand colors */
/* Dark blue */
/* Light blue */
/* Orange */
/* Special cases */
/* HOME PAGE */
body {
  background-color: #e5eff4; }

body.home #page-header {
  /*position: absolute;*/
  z-index: 1000;
  width: 100%; }

/* SLIDESHOW */
/*
    these are just the default styles used in the Cycle2 demo pages.
    you can use these styles or any others that you wish.
*/
/* set border-box so that percents can be used for width, padding, etc (personal preference) */
.cycle-slideshow,
.cycle-slideshow * {
  box-sizing: border-box; }

.cycle-slideshow {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  position: relative;
  background: url("https://malsup.github.com/images/spinner.gif") 50% 50% no-repeat; }

/* slideshow images (for most of the demos, these are the actual "slides") */
.cycle-slideshow img {
  /*
    some of these styles will be set by the plugin (by default) but setting them here
    helps avoid flash-of-unstyled-content
    */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  display: block; }

/* in case script does not load */
.cycle-slideshow img:first-child {
  position: static;
  z-index: 100; }

/* pager */
.cycle-pager {
  text-align: center;
  width: 100%;
  z-index: 500;
  position: absolute;
  bottom: 6rem;
  overflow: hidden; }

.cycle-pager span {
  font-family: arial;
  font-size: 4rem;
  width: 2rem;
  height: 2rem;
  line-height: 1;
  display: inline-block;
  color: #009bde;
  cursor: pointer;
  -webkit-text-stroke: white 1px; }

.cycle-pager span.cycle-pager-active {
  color: #ee4611; }

.cycle-pager > * {
  cursor: pointer; }

div#header-video {
  max-width: 1500px;
  margin: 0 auto; }

.ResponsiveVideoPlayer {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%; }

.ResponsiveVideoPlayer embed,
.ResponsiveVideoPlayer iframe,
.ResponsiveVideoPlayer object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: -1px;
  border: none; }

.header-video-caption {
  color: white;
  color: #cc00cc;
  font-size: 5em;
  font-weight: 600;
  z-index: 500;
  top: 300px;
  width: 100%;
  text-align: center; }

@media screen and (min-width: 401px) and (max-width: 700px) {
  .header-video-caption {
    font-size: 3em;
    top: 150px; } }

@media screen and (max-width: 400px) {
  .header-video-caption {
    font-size: 2em;
    top: 100px; } }

div#header-slideshow {
  min-height: 100px;
  /*z-index: 0;*/ }

div#header-slideshow .cycle-slideshow.cont-page {
  min-height: 125px;
  padding-left: 0;
  padding-right: 0; }

div#gallery-caption {
  z-index: 500; }

.header-image-title {
  position: absolute;
  z-index: 10; }

.header-image-title {
  background-color: rgba(255, 255, 255, 0.7);
  bottom: 0;
  color: #004068;
  font-family: Bitter, serif;
  font-size: 2.5em;
  font-weight: 700;
  line-height: 2.5;
  text-align: center;
  width: 100%; }

div#disclaimer {
  padding: 0 5% 5%;
  width: 90%;
  text-align: center; }

h1.disclaimer {
  width: 100%;
  padding: 20px 0; }

div.disclaimer {
  font-size: 1.2em;
  line-height: 1.5em;
  padding: 0 5%; }

@media screen and (max-width: 1000px) {
  div#header-slideshow .cont-page {
    padding-left: 15px;
    padding-right: 15px; }
  div#header-slideshow {
    overflow: hidden; }
  .cycle-slideshow.cont-page {
    width: 150%;
    overflow: hidden;
    transform: translateX(-50%);
    left: 50%; }
  .header-image-title {
    font-size: 1.5em;
    width: 70%;
    left: 15%;
    right: 0;
    top: 100%;
    position: relative;
    line-height: 1.5;
    padding: 2%; }
  .cycle-pager {
    bottom: 6em; } }

@media screen and (max-height: 500px) {
  .cycle-slideshow.cont-page {
    width: 100%;
    overflow: hidden;
    transform: unset;
    left: 0; }
  .header-image-title {
    width: 100%;
    left: 0;
    right: 0; } }
