/* INSIDE TEMPLATE*/
/* Typography */
/* Brand colors */
/* Dark blue */
/* Light blue */
/* Orange */
/* Special cases */
body.inside {
  background-color: #ffffff; }
  body.inside #content.cont-root {
    padding: 50px 0; }
  body.inside #content .column.left {
    width: 38%; }
  body.inside #content .column.right {
    width: 58%; }

@media screen and (max-width: 890px) {
  body.inside #content .column.right {
    width: 52%; } }

body.inside .page-title {
  position: relative;
  top: 0;
  display: inline-block;
  padding: 13px 10px 13px 0;
  left: 0;
  font-weight: 400;
  font-size: 1.7em;
  background-color: #e5eff4;
  color: #004068;
  z-index: 1;
  line-height: 1.1em;
  margin-bottom: 20px;
  text-transform: uppercase; }

.form-filter.toggle {
  font-weight: 800;
  line-height: 2em;
  margin-bottom: 30px; }

.form-filter.toggle .toggle-link-active {
  color: #ee4611; }

nav.secondary-nav {
  font-weight: 800;
  line-height: 1.5em; }

nav.secondary-nav.upper a {
  text-decoration: underline; }

nav.secondary-nav.lower {
  margin-bottom: 20px; }

nav.secondary-nav.upper {
  display: none;
  width: 96%;
  padding: 0 2%;
  margin-bottom: 30px; }

nav.secondary-nav.upper li {
  display: inline-block; }

nav.secondary-nav.upper span.nav-delimiter {
  margin: 0 10px; }

nav h2.leftnav_resort_label {
  padding-bottom: 6px; }

nav h2.leftnav_resort_label a {
  font-size: 0.9em; }

.welcome {
  text-align: right; }

div.skinh_member_add_event p.row_prompt {
  margin-bottom: 0; }

div.skinh_member_add_event .row.status {
  display: none; }

div.skinh_member_add_event input,
div.skinh_member_add_event select {
  padding: 5px; }

div.skinh_member_add_event input.readonly {
  background: #ccc;
  border: 1px dotted #333; }

.skinh_member_myevents {
  font-size: 12px; }

.skinh_member_myevents i.fa {
  font-size: 14px; }

.skinh_member_myevents th {
  text-align: left;
  padding: 2px; }

.skinh_member_myevents td {
  padding: 2px; }

@media screen and (max-width: 1000px) {
  nav.secondary-nav.upper {
    display: block; }
  nav.secondary-nav.lower {
    display: none; }
  body.inside #content .column.left {
    position: relative;
    width: 360px;
    margin: 0 auto;
    float: none; }
  body.inside #content .column.right {
    margin-left: 0;
    float: none;
    padding: 0 2%;
    width: 96%;
    margin-bottom: 40px; }
  div#header-image .page-title.noimage {
    display: inline-block; } }

@media screen and (max-width: 360px) {
  body.inside #content .column.left {
    width: 290px;
    min-width: 290px; } }
