/* Typography */
/* Brand colors */
/* Dark blue */
/* Light blue */
/* Orange */
/* Special cases */
/* Component styling */
/* forms, lists, messages, etc */
/* Yankee ads */
@media screen and (max-width: 360px) {
  .ad iframe {
    width: auto !important; }
  .ad iframe table {
    width: auto !important; } }

/* Structural */
div.cont-root {
  width: 100%; }

.cont-page {
  margin: 0 auto; }

.cont-img img {
  width: unset; }

.cont-column {
  width: 100%;
  clear: both; }

/* old .clearfix */
.clearfix {
  float: none;
  clear: both;
  width: 100%; }

@media screen and (min-width: 800px) {
  .column.left {
    float: left; }
  .column.right {
    float: right; } }

/* necessary? */
button {
  cursor: pointer;
  margin: 1em 0; }

/* FEU Forms */
fieldset.skinh-form {
  border: none; }

.skinh-form div.message {
  height: 20px; }

.skinh-form .invalid {
  color: #ee4611; }

.skinh-form div {
  margin: 10px 0; }

.skinh-form label {
  display: inline-block;
  zoom: 1;
  *display: inline;
  width: 80px; }

.skinh-form label.label {
  width: 80px; }

.skinh-form input {
  margin: 0 0 0 10px; }

.skinh-form div.submit {
  padding-left: 80px; }

.skinh-form legend {
  font-weight: bold; }

/* login */
#form-member-login a {
  margin-left: 20px; }

div#form-member-login-remember {
  margin-left: 80px; }

/* logout */
/* Ski NH Day Contest form */
.form-contest-entry input {
  border: 1px solid #AAA;
  border-radius: 5px;
  padding: 3px 5px;
  background-color: #fbfbfb; }

.form-contest-entry .fb_invalid input {
  background-color: #fddfdf;
  border: 1px solid #f1a899; }

.form-contest-entry .form-copy,
.form-contest-entry .form-label {
  margin: 5px 0 10px; }

.form-contest-entry .form-label {
  display: inline-block;
  width: 120px;
  font-weight: 700; }

.form-contest-entry .form-input-newsletter {
  margin: 20px 0 30px; }

.form-contest-entry input[type=submit] {
  margin-top: 40px;
  background-color: #ee4611;
  border-radius: 0;
  padding: 8px 15px;
  border: 0;
  color: #ffffff;
  font-size: 1.0rem;
  cursor: pointer; }

/* FILTER FORM BITS */
fieldset.form-filter {
  margin-top: 30px;
  margin-bottom: 30px; }

fieldset.form-filter .intro {
  font-weight: 600;
  margin-bottom: 20px; }

fieldset.form-filter .heading {
  font-size: 1.3em;
  font-weight: 600;
  margin-bottom: 20px; }

fieldset.form-filter .label-primary {
  font-size: 1.1em;
  font-weight: 800;
  margin-bottom: 5px; }

fieldset.form-filter .label-secondary {
  font-size: 0.9em;
  font-weight: 800;
  margin-bottom: 5px; }

fieldset.form-filter .input {
  margin-bottom: 15px; }

fieldset.form-filter .control input {
  border: 0;
  background-color: #e5eff4;
  padding: 5px;
  font-size: 1.1em;
  margin-right: 5px;
  font-weight: 600; }

/* LIST BITS */
.list-item {
  border-bottom: 1px solid #d3e2ea;
  padding: 1em 0;
  margin-bottom: 1em; }

.copy .list-content a {
  text-decoration: none;
  color: #000000; }

.list-heading-primary {
  color: #ee4611;
  margin-bottom: 15px;
  line-height: 1.1em; }

.list-link-more {
  font-weight: 600; }

.news-pr .list-title {
  margin-bottom: 40px; }

.news-pr .list-heading-primary {
  color: #004068; }

.news-pr .list-item-summary {
  font-weight: 800; }

.news-pr #news-detail-return-link {
  color: #ee4611; }

.news-pr .list-link-more {
  color: #ee4611; }

.news-pr a.news-link-more-posts {
  padding: 10px 15px;
  background-color: #ee4611;
  color: #ffffff;
  text-decoration: none; }

.deals .list-content p {
  margin-bottom: 15px; }

.list-datum.deal-days {
  font-weight: 400;
  color: #004068; }

.list-item header {
  display: table;
  width: 100%; }

.list-item .headings {
  display: table-cell;
  width: 62.5%; }

.calendar-list .no-events,
.calendar-list .title {
  margin-top: 40px; }

.calendar-list .event-title {
  margin-top: 15px; }

.calendar-list .event-date {
  font-weight: 600; }

.calendar-list header {
  margin-top: 15px;
  margin-bottom: 15px; }

.calendar-list section.day {
  margin-top: 40px; }

.calendar-list section.resort {
  margin-top: 25px;
  min-height: 100px; }

.events.list-content {
  margin-left: 130px; }

.conditions-link-active {
  color: #ee4611; }

.conditions .list-item {
  padding-bottom: 10px;
  margin-bottom: 5px;
  border-bottom: 2px dotted #000000;
  min-height: 0; }

.conditions .list-heading-primary {
  margin-bottom: 5px;
  font-size: 1.5em; }
  .conditions .list-heading-primary a {
    color: #004068; }

.conditions .list-content {
  width: 100%;
  float: none; }
  .conditions .list-content .url a {
    color: #ee4611;
    margin-right: 1em; }

.conditions .list-item .headings {
  display: block;
  width: 100%; }
  .conditions .list-item .headings h3,
  .conditions .list-item .headings p {
    display: inline-block; }
  .conditions .list-item .headings h3 {
    margin-right: 10px; }
  .conditions .list-item .headings p {
    font-size: 0.9em; }

.list-item .call-to-action {
  display: table-cell;
  width: 37.5%;
  font-weight: 800;
  color: #ee4611;
  text-decoration: underline; }

.list-item .label {
  margin-bottom: 5px; }

.list-item .url {
  font-weight: 800; }

.list-item .address {
  font-weight: 600; }

.list-item .data {
  display: table;
  width: 100%;
  font-size: 1.0em;
  font-family: "HelveticaNeue-Condensed", "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.list-item .data p {
  display: table-cell;
  width: 50%; }

.list-item .data p.narrow {
  display: table-cell;
  width: 40%; }

.list-item .data p.wide {
  display: table-cell;
  width: 60%; }

.list-item .data .top {
  width: 49%;
  display: inline-table;
  height: 60px; }

.list-item .data .bottom {
  width: 49%;
  display: inline-table;
  height: 60px; }

.list-item .data .label {
  display: inline-block;
  height: 20px;
  font-size: 1.1em;
  vertical-align: top;
  font-weight: 800; }

.list-item .data .datum {
  display: inline-block;
  padding-top: 10px; }

.list-item .data p.wide .datum {
  padding-top: 36px; }

.list-item .data p.wide .datum {
  font-weight: 800;
  font-size: 0.85em; }

.list-logo {
  float: left;
  margin-right: 5%;
  min-height: 60px;
  width: 25%; }

.list-content {
  width: 70%;
  float: left; }
  .list-content .button {
    font-weight: 800;
    margin-right: 1em;
    text-transform: uppercase; }

@media screen and (max-width: 525px) {
  .list-content,
  .list-logo {
    width: 100%;
    float: none; }
  .list-item .data {
    display: block; }
  .list-item .data .bottom,
  .list-item .data .top {
    display: table;
    width: 100%; }
  .list-item .data .bottom {
    margin-top: 20px; }
  .list-item .data .datum {
    padding-top: 15px; }
  .list-item .data p.wide .datum {
    padding-top: 16px; }
  .list-item .data p.narrow {
    width: 20%; }
  .list-item .data p.wide {
    width: 80%; } }

div.ad {
  margin-top: 20px; }

.copy p {
  margin-bottom: 15px; }

.copy {
  color: #555555;
  font-size: 1em;
  font-weight: 600;
  line-height: 1.35em; }

.copy a {
  text-decoration: underline; }

.copy .callout {
  font-size: 2.4em;
  line-height: 1.3em;
  font-weight: 300; }

.copy .callout-orange {
  font-size: 2.4em;
  line-height: 1.3em;
  font-weight: 300;
  color: #ee4611; }

/* Nav-related useful classes */
.nav ul,
ul.nav {
  list-style-type: none;
  list-style-position: outside;
  margin-left: 0; }

ul.no-bull {
  list-style-type: none;
  list-style-position: outside;
  margin-left: 0; }

.nav a {
  text-decoration: none; }

.nav a:hover {
  text-decoration: underline; }

a.aname.hidden {
  position: absolute;
  top: 0;
  visibility: hidden;
  display: block;
  width: 0;
  height: 0; }

/* Print */
.print-only {
  display: none; }

/* TinyMCE insertvideo plugin */
div.mce-video {
  padding-bottom: 60%;
  /* This controls the aspect ratio */ }

div.mce-video.map {
  padding-bottom: 130%;
  /* This controls the aspect ratio */ }

div.mce-video iframe {
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
