/* HEADER IMAGE FOR INSIDE TEMPLATES */
/* Typography */
/* Brand colors */
/* Dark blue */
/* Light blue */
/* Orange */
/* Special cases */
p#header-image-caption {
  color: #000000; }

div#header-image #page-title,
div#header-image .page-title {
  position: absolute;
  top: 11px;
  display: inline-block;
  padding: 13px 10px 13px 2%;
  left: 0;
  font-weight: 400;
  font-size: 1.7em;
  background-color: #ffffff;
  z-index: 1;
  line-height: 1.1em;
  text-transform: uppercase; }

body.resort div#header-image #page-title {
  top: auto;
  bottom: 32px; }

@media screen and (max-width: 980px) {
  p#header-image-caption {
    padding: 0 2%;
    width: 96%; } }
