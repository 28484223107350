/* DRAWER - UNIVERSAL */
/* Typography */
/* Brand colors */
/* Dark blue */
/* Light blue */
/* Orange */
/* Special cases */
#drawer-panel {
  margin-top: 30px; }

#drawer,
#drawer-mobile {
  width: 99.8%;
  height: 78px;
  display: table; }

#drawer-mobile {
  padding-top: 0;
  height: 78px; }

#drawer section,
#drawer-mobile section {
  display: table-cell;
  width: 25%; }

#drawer {
  background: transparent url("/local/img/bg_drawer.png") repeat 0 0; }

#drawer section.open {
  padding-bottom: 15px; }

#drawer section div.contents {
  display: none; }

#drawer section div.wrapper {
  padding: 10px 6%; }

#drawer section div.wrapper p {
  margin-bottom: 10px; }

.drawer-border {
  width: 99.8%;
  height: 78px;
  position: absolute;
  border: 1px solid #6fa7c4;
  top: 0;
  cursor: pointer;
  z-index: 1; }

#drawer-mobile .drawer-border {
  cursor: default; }

#drawer .group {
  display: block;
  float: left;
  width: 50%; }

.drawer-border.open {
  border-bottom: 1px solid transparent; }

div.spacer {
  background-color: #e5eff4;
  height: 20px; }

#drawer.resort div.spacer {
  background-color: #ffffff;
  height: 25px; }

#drawer h2.open {
  background: #e5eff4 url("root_url/local/img/bg_drawer_header.png") repeat-x 0 50px; }

#drawer h2,
#drawer-mobile h2 {
  text-align: center;
  padding: 0 0 20px;
  line-height: 1em;
  height: 40px;
  max-height: 40px;
  background-color: #e5eff4;
  border-right: 1px solid transparent;
  color: #ee4611;
  font-size: 2.3em;
  cursor: pointer; }

/*#drawer div.border,*/
#drawer h2.border {
  border-right: 1px solid #004068; }

#drawer .label {
  color: #ee4611;
  font-size: 1.15em;
  margin-bottom: 2px;
  text-transform: uppercase; }

#drawer p.datum {
  margin-bottom: 25px;
  font-weight: 800;
  font-size: 1.1em; }

/* DRAWER - RESORT */
#drawer-mobile.resort h2,
#drawer.resort h2 {
  padding: 0 0 20px;
  margin: 0;
  height: 35px;
  max-height: 35px;
  background-color: #ffffff;
  font-size: 1.7em; }

#drawer.resort h2.open {
  background: #ffffff url("root_url/local/img/bg_drawer_header.png") repeat-x 0 45px; }

/* DRAWER - HOME */
#drawer #expand {
  position: absolute;
  background-color: #004068;
  color: #ffffff;
  top: -13px;
  z-index: 10;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 134px;
  height: 27px;
  line-height: 27px;
  text-align: center;
  font-size: 1.4em;
  font-weight: 600;
  cursor: pointer; }

#drawer a.more {
  font-weight: 600;
  text-decoration: none; }

#drawer-mobile h2 {
  height: 58px;
  padding-top: 20px; }

/* DRAWER - MQ */
@media screen and (max-width: 768px) {
  #drawer h2,
  #drawer-mobile h2 {
    font-size: 1.8em; }
  #drawer .group {
    display: table;
    width: 100%; } }

#drawer-mobile a {
  text-decoration: none; }

#drawer-mobile {
  display: none; }

@media screen and (max-width: 632px) {
  #drawer.home {
    display: none; }
  #drawer-mobile {
    display: table; }
  .drawer-border {
    z-index: 0; }
  #drawer-mobile h2 {
    background-color: transparent; } }

@media screen and (max-width: 400px) {
  #drawer section {
    display: block;
    width: 100%; }
  .drawer-border {
    z-index: 0; }
  #drawer h2.border {
    border-right: 1px solid transparent; } }

@media screen and (max-width: 340px) {
  #drawer-mobile section {
    display: block;
    width: 100%; }
  #drawer-mobile h2 {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 28px; }
  .drawer-border {
    display: none; } }
