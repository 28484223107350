/* Typography */
/* Brand colors */
/* Dark blue */
/* Light blue */
/* Orange */
/* Special cases */
body {
  height: 100%;
  line-height: 1.3em;
  font-family: Raleway, sans-serif !important;
  font-weight: 400;
  color: #004068; }

fieldset,
img,
svg {
  border: 0;
  max-width: 100%; }

div {
  position: relative; }

h1 {
  font-size: 1.9em;
  line-height: 1.1em; }

h2 {
  color: #004068;
  font-size: 1.6em;
  line-height: 1.5;
  margin-bottom: 0.5em; }

h3 {
  color: #ee4611;
  font-size: 1.4em;
  margin-bottom: 0.5em; }

h4 {
  font-size: 1.0em; }

h5 {
  font-size: 1.2em;
  font-weight: normal; }

h6 {
  font-size: 0.8em; }

a,
a:hover,
a:visited {
  color: inherit;
  text-decoration: none; }

ol,
ul {
  list-style-position: outside;
  margin-left: 25px; }

article,
aside,
figure,
footer,
header,
main,
nav,
section {
  display: block;
  position: relative; }

main ul {
  -webkit-margin-after: 1em;
          margin-block-end: 1em; }
