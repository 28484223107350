/* Styling for reusable chunks of pages */
/* header, footer, nav, widget, etc */
/* Typography */
/* Brand colors */
/* Dark blue */
/* Light blue */
/* Orange */
/* Special cases */
header#page-header {
  padding-top: 2em;
  font-size: 1rem;
  font-weight: 400;
  background: #004068;
  border-bottom: 2px solid #ee4611;
  margin: 0 auto; }
  header#page-header .inside #header {
    padding: 0; }
  header#page-header #header-logo {
    display: inline-block;
    width: 18%;
    vertical-align: text-top; }
  header#page-header #header-right {
    display: inline-block;
    text-align: right;
    width: 81%;
    position: unset;
    vertical-align: text-top; }
  header#page-header #header-top {
    font-size: 0.9em;
    line-height: 1;
    margin-bottom: 4em; }
  header#page-header #lang {
    display: inline-block;
    margin-right: 10px;
    font-weight: 800;
    color: #ffffff;
    position: absolute;
    right: -5.5em;
    bottom: 0; }
  header#page-header #lang a {
    text-decoration: none; }
  header#page-header #lang img {
    margin: 0 2px;
    border: 1px solid white;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    padding: 0;
    vertical-align: bottom; }
  header#page-header #page-header.inside #header #social {
    position: absolute;
    top: 12px;
    right: 0;
    padding-right: 10px;
    z-index: 1; }
  header#page-header #social {
    display: inline-block; }
    header#page-header #social .icon {
      fill: #ee4611;
      height: 30px;
      width: 30px;
      vertical-align: bottom; }
      header#page-header #social .icon:hover {
        fill: #009bde; }
  header#page-header #nav-special {
    display: inline-block;
    z-index: 1;
    font-weight: 600;
    color: #ffffff;
    text-transform: uppercase; }
    header#page-header #nav-special li {
      display: inline-block;
      padding-right: 1.5em; }
  header#page-header #skiing-types {
    display: inline-block;
    vertical-align: top;
    z-index: 510; }
  header#page-header #skiing-types > ul > li {
    display: inline-block; }
  header#page-header #skiing-types > li.menuparent > a {
    background: #ee4611;
    color: #e5eff4;
    font-weight: 800;
    padding: 4px 10px;
    margin-right: 12px;
    text-transform: uppercase; }
  header#page-header li.menuparent > a {
    padding-left: 2em; }
  header#page-header li.menuparent.first > a {
    padding-left: 0.5em; }
  header#page-header li.menuparent .sub {
    position: absolute;
    visibility: hidden; }
  header#page-header li.menuparent:hover .sub {
    z-index: 1000;
    visibility: visible; }
  header#page-header ul#nav-main span.nav-delimiter {
    display: none; }
  header#page-header ul#nav-main > li > a {
    display: inline-block;
    color: #ffffff;
    text-transform: uppercase; }
  header#page-header .cont-page {
    position: static; }
  header#page-header #header-bottom,
  header#page-header #header-nav,
  header#page-header nav {
    position: unset; }
  header#page-header ul.nav {
    display: inline-block; }
  header#page-header ul.nav ul li a {
    color: #ffffff;
    margin-left: -1em; }
  header#page-header li.menuparent {
    display: inline-block;
    padding-bottom: 2em; }
  header#page-header span.mobile-arrow {
    display: none; }
  header#page-header .sub {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%; }
    header#page-header .sub .background {
      background: #009bde;
      border-top: 2px solid #ee4611;
      padding: 2em; }
    header#page-header .sub ul {
      -moz-column-count: 5;
           column-count: 5;
      -moz-column-fill: auto;
           column-fill: auto;
      -moz-column-gap: 2em;
           column-gap: 2em;
      height: 12em; }
    header#page-header .sub li {
      text-align: left;
      font-size: 0.8em;
      font-weight: 400;
      text-transform: uppercase;
      vertical-align: text-top;
      line-height: 2; }
  header#page-header #nav-main ul.nav {
    -moz-column-count: 2;
         column-count: 2;
    -moz-column-fill: auto;
         column-fill: auto;
    height: 10em;
    min-width: 500px;
    width: 45%; }

a#buy-now {
  background-color: #555555;
  border: none;
  border-radius: 0;
  color: #66ff66;
  font-family: Raleway, sans-serif;
  font-size: 1em;
  padding: 0.5em 1em;
  text-decoration: none;
  line-height: 4;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase; }

a#buy-now:hover {
  background-color: #333333; }

#resorts {
  display: block; }
  #resorts .cont-page {
    padding: 50px 1%;
    width: 98%; }
  #resorts .column {
    float: left;
    width: 50%; }
  #resorts h4 {
    color: #ee4611;
    text-transform: uppercase; }
  #resorts ul {
    -moz-column-count: 4;
         column-count: 4;
    list-style-type: none;
    list-style-position: outside;
    margin-left: 0; }
  #resorts li {
    position: relative;
    font-size: 0.8em;
    font-weight: 400;
    line-height: 1; }
  #resorts li.left {
    float: left;
    width: 51%; }
  #resorts li.right {
    width: 49%;
    margin-left: 50%; }
  #resorts li.rightfirst {
    margin-top: -500px; }

footer {
  min-height: 300px;
  background-color: #004068;
  color: #ffffff;
  font-weight: 300; }
  footer .cont-page {
    padding: 0 1%;
    width: 98%; }
  footer .cont-page > div,
  footer .cont-page > nav {
    width: 50%; }
  footer #sponsors {
    background: #ffffff;
    border-top: 2px solid #e5eff4;
    color: #000000;
    padding-top: 2em;
    text-align: center; }
    footer #sponsors img {
      padding: 4%;
      max-width: 15%; }
  footer #search-form {
    float: left; }
  footer #footer-nav {
    padding-top: 32px;
    float: right; }
  footer #footer-nav h4 {
    color: #ffffff; }
  footer #nav-special li,
  footer ul#nav-footer > li {
    display: inline-block;
    padding-right: 1.5em; }
  footer ul#nav-footer .nav-delimiter {
    display: none; }
  footer #address {
    clear: left;
    padding-top: 1em;
    line-height: 2;
    font-style: normal; }
  footer #state-funding-notice {
    padding-top: 50px;
    font-size: 0.7rem; }
  footer #footer-nav ul {
    padding-top: 1em;
    line-height: 1.75em; }
  footer #footer-nav #logos {
    margin-top: 40px; }
  footer #search-form form {
    display: inline-block;
    margin-right: 5px;
    margin-top: 25px; }
  footer .search-input {
    border: 0;
    background-color: #e5eff4;
    color: #2273a7;
    width: 280px;
    height: 32px;
    line-height: 32px;
    padding-left: 10px;
    font-size: 1em;
    font-weight: 800; }
  footer .search-button {
    border: 0;
    background-color: #004068;
    font-size: 1em;
    font-weight: 800;
    color: #ee4611; }

a.enews-signup,
a.enews-signup:hover,
a.enews-signup:visited {
  display: inline-block;
  font-weight: 800;
  color: #009bde;
  padding-right: 1.5em;
  text-transform: uppercase; }

/***************/
/* GOOGLE MAPS */
/***************/
.google-infowindow-cont a {
  text-decoration: none; }

.google-infowindow-logo {
  width: 75px; }

.google-infowindow-name {
  font-size: 1.4em;
  line-height: 1.0em;
  color: #004068; }

a.google-infowindow-dirlink {
  line-height: 2.5em;
  font-weight: 600;
  color: #ee4611; }

/**************/
/* MOBILE NAV */
/**************/
#nav-mobile-wrapper {
  display: none; }

div#ctrl-menu {
  border-top: 3px solid #009bde;
  border-bottom: 3px solid #009bde;
  cursor: pointer;
  height: 13px;
  width: 2em; }

div#ctrl-menu:before {
  background: #009bde;
  height: 3px;
  width: 2em;
  content: '';
  position: absolute;
  top: 5px;
  right: 0; }

#ctrl-menu {
  display: none; }

@media screen and (max-width: 1500px) {
  header#page-header .sub ul {
    -moz-column-count: 4;
         column-count: 4;
    -moz-column-fill: auto;
         column-fill: auto;
    -moz-column-gap: 2em;
         column-gap: 2em;
    height: 15em; } }

@media screen and (max-width: 1350px) {
  header#page-header #lang {
    display: block;
    right: 0;
    top: 3em; } }

@media screen and (max-width: 1250px) {
  header#page-header .sub ul {
    -moz-column-count: 3;
         column-count: 3;
    -moz-column-fill: auto;
         column-fill: auto;
    -moz-column-gap: 2em;
         column-gap: 2em;
    height: 18em; } }

/* Switch to mobile nav */
@media screen and (max-width: 1000px) {
  header#page-header {
    box-sizing: border-box;
    padding: 1em 10%;
    position: unset;
    /* X when menu open */ }
    header#page-header #header-logo {
      width: 30%; }
    header#page-header #header-right {
      box-sizing: border-box;
      width: 68%; }
    header#page-header #header-right.open {
      background: #004068;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      padding: 2.5em 10%;
      margin: 0;
      width: 100%;
      z-index: 999; }
    header#page-header #header-top {
      display: none;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      padding: 2rem 10%;
      text-align: left;
      border-top: 2px solid #ee4611; }
    header#page-header #header-top.show {
      display: block; }
    header#page-header #header-nav {
      display: none;
      font-size: 0.9em; }
    header#page-header .open #header-nav {
      display: block;
      padding-top: 15%; }
    header#page-header #header-nav ul,
    header#page-header #header-nav ul li {
      display: block;
      text-align: left; }
    header#page-header #nav-special {
      line-height: 2; }
      header#page-header #nav-special li {
        display: block;
        text-align: left; }
    header#page-header li.menuparent.active .sub,
    header#page-header li.menuparent:hover .sub {
      top: 0;
      left: 40%; }
    header#page-header .sub .background {
      background: unset;
      border: none;
      padding: 0; }
      header#page-header .sub .background li {
        font-size: 0.9em;
        line-height: 1.5;
        padding-bottom: 0.5em; }
    header#page-header #nav-main ul.nav {
      width: 100%;
      min-width: unset;
      height: 50%;
      -moz-column-count: 1;
           column-count: 1; }
    header#page-header ul#nav-main {
      position: relative; }
    header#page-header ul.nav ul li a {
      color: #009bde;
      font-size: 0.9em;
      font-weight: 800; }
    header#page-header li.menuparent {
      padding-bottom: 1em; }
    header#page-header li.menuparent > a {
      padding-left: 0; }
    header#page-header li.menuparent.first > a {
      padding-left: 0; }
    header#page-header #lang {
      right: 0;
      left: 0;
      margin: 0; }
    header#page-header div#ctrl-menu {
      cursor: pointer;
      display: block;
      float: right;
      transform: translateY(100%);
      -webkit-transform: translateY(100%);
      height: 13px;
      width: 2em;
      border-top: 3px solid #009bde;
      border-bottom: 3px solid #009bde; }
    header#page-header div#ctrl-menu:before {
      background: #009bde;
      height: 3px;
      width: 2em;
      content: '';
      position: absolute;
      top: 5px;
      right: 0; }
    header#page-header div#ctrl-menu.close {
      border-top: none;
      transform: rotate(45deg) translate(0, -0.5em);
      -webkit-transform: rotate(45deg) translate(0, -0.5em); }
    header#page-header div#ctrl-menu.close:before {
      transform: rotate(90deg) translate(0.5em, 0px);
      -webkit-transform: rotate(90deg) translate(0.5em, 0px); }
    header#page-header a.enews-signup {
      padding: 0;
      display: block;
      line-height: 2; }
    header#page-header #social {
      display: block;
      text-align: center;
      margin: 1rem; }
  footer .cont-page > div,
  footer .cont-page > nav {
    width: 100%; }
  footer #nav-footer {
    padding: 0; }
  ul#nav-footer > li {
    display: block;
    text-align: left; }
  a#buy-now {
    float: none;
    line-height: 3;
    margin-left: 0; } }

@media screen and (max-width: 450px) {
  #resorts ul {
    -moz-column-count: 1;
         column-count: 1; }
  header .open #header-nav {
    padding-top: 0; }
  header #lang img {
    margin: 5px; }
  footer #sponsors img {
    max-width: 40%; } }

@media screen and (max-height: 500px) and (max-width: 1000px) {
  header #header-top {
    box-sizing: border-box;
    border-left: 2px solid #ee4611;
    border-top: none;
    left: 60%;
    padding: 2.5rem 0;
    top: 0;
    width: 40%; }
  header #header-logo {
    width: 15%; }
  header #header-right {
    width: 85%;
    margin-top: 0; }
  header #nav-special {
    margin: 2rem; }
  header #social {
    display: block;
    text-align: center;
    margin: 0; }
  header .open #header-nav div#header-bottom {
    width: 60%; }
  header div#ctrl-menu.close {
    position: absolute;
    right: 10%;
    top: 2em;
    float: none; }
  header .open #header-nav {
    display: block;
    padding-top: 0;
    margin: 2rem 1rem; } }

#manage_member_FEU .button {
  -webkit-appearance: button;
  background-color: buttonface;
  border-color: #767676 #858585;
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 1px;
  padding: 5px 20px;
  text-decoration: none; }

#manage_member_FEU input[type="submit"] {
  padding: 5px 20px;
  margin: 0 10px; }

#manage_member_FEU .pagetext {
  display: block;
  margin-bottom: 5px; }

#manage_member_FEU .pageinput {
  display: block;
  padding: 5px;
  margin-top: 0;
  width: 95%; }

#manage_member_FEU .pageoverflow {
  margin-top: 15px; }

#manage_member_FEU .socials,
#manage_member_FEU .stats {
  background: #eee;
  padding: 1em;
  width: 100%; }

#manage_member_FEU .socials .pageoverflow:nth-child(odd) {
  padding: 0.5em;
  background: #fff; }

#manage_member_FEU .redactor-box {
  border: 1px solid rgba(0, 0, 0, 0.075);
  border-radius: 3px;
  padding: 1em; }

#manage_member_FEU .redactor-toolbar {
  z-index: 1 !important; }
