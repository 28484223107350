/* Typography */
/* Brand colors */
/* Dark blue */
/* Light blue */
/* Orange */
/* Special cases */
body.blog {
  background-color: #e5eff4;
  /*summary*/
  /*detail*/ }
  body.blog #category-filter {
    background: #009bde;
    margin-top: 50px; }
  body.blog div#category-filter ul {
    margin: 0 auto;
    display: table;
    list-style: none; }
  body.blog #category-filter li {
    display: inline-block;
    padding: 1em;
    list-style: none; }
    body.blog #category-filter li a {
      color: #ffffff;
      font-size: 1.1em;
      font-weight: 600;
      text-transform: uppercase; }
  body.blog #tiles section.tile:nth-child(even) {
    margin-right: 0; }
  @media screen and (min-width: 750px) {
    body.blog #tiles section.tile:nth-child(even) {
      margin-right: 1.75%; }
    body.blog #tiles section.tile:nth-child(3n) {
      margin-right: 0; }
    body.blog #tiles section.tile:nth-child(3n-2) {
      margin-left: 0; } }
  body.blog div#pagination {
    text-align: center;
    padding-bottom: 2rem; }
    body.blog div#pagination a {
      font-size: 1.1em;
      font-weight: 600;
      margin: 1em; }
  body.blog span.current {
    color: #ee4611; }
  body.blog .item {
    background: #d3e2ea;
    box-sizing: border-box;
    padding-top: 4em; }
  body.blog div#blogcontent {
    color: #000000;
    line-height: 1.5;
    padding: 0 2em; }
  @media screen and (min-width: 1000px) {
    body.blog div#blogcontent {
      padding: 0 5em; } }
  body.blog h1.item-title {
    color: #009bde;
    font-size: 2em;
    font-weight: 600;
    margin: 0.5em 0; }
  body.blog #blogcontent h2 {
    font-size: 1.6em;
    color: #004068;
    font-weight: 600;
    margin-bottom: 0.5em; }
  body.blog #blogcontent h3 {
    color: #ee4611;
    font-size: 1.2em;
    font-weight: 600; }
  body.blog a.return-link {
    color: #004068;
    display: block;
    font-size: 0.9em;
    font-weight: 600;
    text-decoration: none; }
  body.blog div#date {
    color: #009bde;
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 1.5em; }
  body.blog #blogcontent li {
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 1em; }
  body.blog #blogcontent blockquote {
    color: #009bde;
    font-size: 1.2em;
    font-weight: 600;
    padding: 1em 2em; }
  body.blog div.share {
    text-align: center;
    margin: 4em 0; }
  body.blog .share div {
    display: inline-block;
    line-height: 1;
    vertical-align: text-top; }
  body.blog .author img {
    border-radius: 50%; }
  body.blog .author {
    color: #009bde;
    font-size: 1.2em;
    font-weight: 600;
    line-height: 1.5;
    text-align: center;
    text-transform: uppercase; }
  body.blog .author span.name {
    color: #004068; }
  body.blog div#tags {
    text-align: center;
    text-transform: uppercase;
    padding: 4em 0; }
    body.blog div#tags a {
      border-right: solid 2px;
      color: #009bde;
      font-size: 1.1em;
      font-weight: 600;
      padding: 0 0.5em;
      text-decoration: none; }
    body.blog div#tags a:hover {
      text-decoration: underline; }
    body.blog div#tags a:last-child {
      border: none; }
  body.blog #blogcontent input,
  body.blog #blogcontent textarea {
    border: none;
    border-radius: 5px;
    box-sizing: border-box;
    font-family: Raleway, sans-serif;
    font-size: 1.1em;
    margin: 0.5em 0;
    padding: 0.5em;
    width: 100%; }
  body.blog ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #c6c7c9; }
  body.blog ::-moz-placeholder {
    /* Firefox 19+ */
    color: #c6c7c9; }
  body.blog :-ms-input-placeholder {
    /* IE 10+ */
    color: #c6c7c9; }
  body.blog :-moz-placeholder {
    /* Firefox 18- */
    color: #c6c7c9; }
  body.blog button.btn {
    background: #009bde;
    border: none;
    border-radius: 5px;
    color: #ffffff;
    font-family: Raleway, sans-serif;
    font-size: 1.1em;
    font-weight: 600;
    margin: 1em 0;
    padding: 0.5em 1em; }
  body.blog #blogcontent .timestamp {
    color: #009bde;
    font-size: 1.2em;
    line-height: 1.5; }
  body.blog .cgfeedback_summary_item {
    color: #004068;
    font-weight: 600;
    padding-bottom: 1em; }
  body.blog span.title {
    text-transform: uppercase;
    font-size: 1.2em; }
  body.blog .error {
    color: red; }
  body.blog .related.cont-root {
    background: #004068; }
  body.blog .related h3 {
    color: #ffffff;
    font-size: 2.1em;
    font-weight: 600;
    padding: 3em 0;
    text-align: center;
    text-transform: uppercase; }
