/* Typography */
/* Brand colors */
/* Dark blue */
/* Light blue */
/* Orange */
/* Special cases */
body.inside #content .column.left.calendar {
  display: none;
  min-width: 10px;
  width: 18%; }

body.inside #content .column.left.event_resorts {
  margin-bottom: 20px;
  padding-left: 10px; }

/* JAH */
body.inside #content .column.right.fullwidth {
  width: 98%;
  margin: 0 1% 0 auto;
  box-sizing: border-box; }

/* FULL WIDTH CALENDAR   JAH */
h1.template_fullwidth {
  background-color: #004068;
  color: #ffffff;
  text-transform: uppercase;
  display: inline-block;
  font-size: 1.7em;
  font-weight: 300;
  left: 0;
  line-height: 1.1em;
  padding: 13px 10px;
  position: relative;
  top: 0;
  z-index: 1; }

#calendar_skinh {
  margin-left: -8px; }

#calendar_skinh table {
  table-layout: fixed; }

#calendar_skinh td {
  border: 1px solid #ddd;
  vertical-align: top;
  margin: 0;
  padding: 0 0 20px;
  width: 14%; }

#calendar_skinh a.calendar-daylist,
#calendar_skinh span.calendar-date-noevent {
  font-weight: bold;
  text-decoration: none;
  float: right;
  font-size: 1.1em;
  padding-right: 5px; }

#calendar_skinh td.cal-day {
  height: 100px; }

#calendar_skinh td.cal-day ul {
  clear: both;
  margin: 0;
  width: 100%; }

#calendar_skinh td.cal-day li {
  margin: 3px 0;
  list-style: none;
  max-width: 134px;
  padding-right: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 2px;
  background: white;
  color: #fff; }

#calendar_skinh td.cal-day a.calendar-event {
  display: block;
  float: left;
  width: 85%;
  color: #4F92B6;
  font-size: 0.7em;
  line-height: 0.7em;
  text-decoration: none;
  padding: 7px 0;
  border-radius: 2px; }

#calendar_skinh td.cal-day a.calendar-event:hover {
  background: #4F92B6;
  color: white; }

#calendar_skinh div.more_events_today {
  font-size: 0.7em;
  color: #336686;
  padding: 2px 0;
  border-radius: 2px;
  line-height: 20px; }

#calendar_skinh div.more_events_today a {
  text-decoration: none; }

#calendar_skinh div.more_events_today:hover {
  background: #4F92B6;
  color: #fff; }

#calendar_skinh div.more_events_today .fa-arrow-circle-right {
  font-size: 18px;
  float: right;
  padding-right: 4px;
  padding-top: 3px;
  color: #336686; }

#calendar_skinh .calendar-month {
  padding-right: 20px;
  font-size: 1.3em;
  margin: 20px 0; }

#calendar_skinh .calendar-lbl {
  font-size: 1.6em; }

#calendar_skinh img.calendar_icon {
  float: left;
  padding-bottom: 0;
  margin: 0;
  padding-top: 3px;
  margin-right: 1px; }

.howmany {
  font-weight: bold;
  font-size: 1.3em;
  padding-left: 3px; }

#calendar_skinh .calendar_month_colspan {
  width: 100%;
  text-align: center;
  color: #004068;
  font-size: 1.05em;
  font-weight: bold; }

#calendar_skinh td.calendar_month_colspan {
  border: none !important; }

#calendar_skinh td.calendar_month_colspan a {
  text-decoration: none; }

#calendar_skinh td.calendar_month_colspan a:hover {
  text-decoration: underline; }

#calendar_skinh .calendar-prev {
  display: block;
  text-align: left;
  width: 25%;
  float: left; }

#calendar_skinh .calendar-next {
  display: block;
  text-align: right;
  width: 25%;
  float: left; }

#calendar_skinh .calendar-lbl {
  display: block;
  text-align: center;
  width: 50%;
  float: left; }

.select_resort_header {
  width: 45%;
  float: left;
  color: #000;
  font-family: "Raleway",sans-serif;
  text-transform: uppercase;
  font-weight: 800; }

.view_by_resort {
  color: #000;
  font-family: "Raleway",sans-serif;
  font-size: 1.05em;
  text-transform: uppercase;
  font-weight: 800;
  width: 50%;
  float: left; }

.view_by_resort select {
  width: 90%;
  margin: 0 auto;
  background: #eee;
  font-weight: normal;
  font-family: "Raleway",sans-serif; }

.calendar_select_view_icons svg {
  width: 40px;
  margin: 0 12px 20px; }

.calendar_select_view_icons .headline {
  margin-bottom: 5px;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 1.05em; }

.view_icons_left .calendar_select_view_icons .headline {
  margin-top: 22px; }

.back_to_calendars {
  margin-top: 30px; }

#skinh_list_sorted_by_resort {
  width: 100%;
  min-height: 220px; }

#skinh_list_sorted_by_resort .event-date {
  padding-top: 20px; }

#skinh_list_sorted_by_resort a {
  text-decoration: none;
  color: #ee4611; }

#skinh_list_sorted_by_resort a:hover {
  text-decoration: underline; }

#skinh_list_sorted_by_resort .resort_event_logo {
  width: 100%; }

#skinh_list_sorted_by_resort .resort_event_logo img {
  max-width: 50%; }

#skinh_list_sorted_by_resort h3 {
  padding: 20px 0; }

#skinh_list_sorted_by_resort h3.month {
  color: #004068;
  padding: 30px 0 10px; }

#skinh_list_sorted_by_resort a.calendar-nav {
  color: #004068; }

#skinh_list_sorted_by_resort .calendar-event {
  padding-bottom: 20px; }

.view_icons_right {
  float: right; }

.view_icons_left {
  clear: both;
  color: #000000;
  padding-top: 15px; }

.view_icons_left .calendar_select_view_icons {
  color: #000; }

.calendar_back_to_top {
  width: 160px;
  margin: 10px auto;
  text-align: center;
  background: #ee4611;
  color: #fff;
  padding: 12px 30px; }

.calendar_back_to_top a {
  text-decoration: none;
  cursor: pointer; }

.calendar_back_to_top a:hover {
  text-decoration: underline; }

#cal_event_resort_dropdown {
  width: 240px;
  font-family: "Raleway",sans-serif; }

/* on calendar list */
.list-group-item {
  padding-right: 2em; }

.calendar-list h1 {
  color: #004068; }

.calendar-list-nav a {
  color: #004068;
  font-weight: bold;
  text-decoration: none; }

.calendar-list-nav a:hover {
  text-decoration: underline; }

.calender-list-divider {
  width: 100%;
  height: 1px;
  border-top: 1px dotted #555;
  margin-bottom: 4px; }

.calendar-list .event-date {
  color: #004068; }

/* on calendar single event detail */
#skinh_event_detail h1 {
  padding: 10px 0; }

.cal_event_detail_logo {
  width: 30%; }

.cal_event_detail_logo img {
  width: 100%; }

#skinh_event_detail a {
  color: #ee4611;
  text-decoration: none; }

#skinh_event_detail a:hover {
  text-decoration: underline; }

/*on calendar more than four events on a day */
#skinh_list_multiples_oneday .calendar-event {
  clear: both; }

#skinh_list_multiples_oneday .calendar-event .event_logo {
  float: left;
  position: absolute;
  left: 0;
  top: 0;
  width: 100px; }

#skinh_list_multiples_oneday .calendar-event .event_logo img {
  width: 100%; }

#skinh_list_multiples_oneday h3.date {
  padding: 10px 0; }

#skinh_list_multiples_oneday h3.event_name {
  padding: 0 0 2px;
  /*color:[[$color_brand_primary]]; */ }

#skinh_list_multiples_oneday h4 {
  font-size: 1.0em;
  /*font-style:italic;*/
  margin-top: 15px; }

#skinh_list_multiples_oneday p {
  margin-bottom: 0; }

#skinh_list_multiples_oneday a.more_link,
.calendar-list a.more_link {
  color: #ee4611;
  text-decoration: none; }

#skinh_list_multiples_oneday a.more_link:hover,
.calendar-list a.more_link:hover {
  text-decoration: underline; }

#skinh_list_multiples_oneday .cal_resort_header {
  padding-top: 20px;
  border-top: 1px dotted #999; }

.calendar-details span {
  font-family: "Raleway",sans-serif !important; }

@media screen and (min-width: 481px) {
  #calendar_view_other {
    clear: both;
    display: none; } }

@media screen and (max-width: 800px) {
  h2.template_fullwidth {
    display: none; } }

@media screen and (max-width: 480px) {
  #calendar_skinh {
    display: none; }
  /*.calendar_back_to_top {display:none;}*/
  #calendar_view_other {
    margin-top: 30px;
    padding-top: 40px; }
  #calendar_view_other a {
    color: #ee4611;
    text-decoration: none;
    font-weight: bold; } }

/* * * * * * * * * * * * *   END OF CALENDAR STYLING * * * * * * * * * * * */
/* MONTH CALENDAR */
#month_calendar {
  margin: 0 auto;
  padding: 0;
  width: 100%; }
  #month_calendar table,
  #month_calendar td,
  #month_calendar tr {
    padding: 0;
    vertical-align: initial; }
  #month_calendar #month_calendar_outer {
    width: 100%;
    margin: 0;
    padding: 0;
    table-layout: fixed; }
  #month_calendar #month_calendar_inner {
    width: 100%;
    table-layout: fixed; }
  #month_calendar #month_calendar_inner td {
    width: 14%;
    height: 130px;
    padding: 5px; }
  #month_calendar .event {
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    color: #7f7f7f;
    padding: 2px 0 0; }
  #month_calendar .event a {
    color: #565656;
    text-decoration: underline; }
  #month_calendar .day_cell .all_day,
  #month_calendar .day_cell .multi_day {
    padding: 0 6px;
    line-height: 20px;
    color: #7f7f7f; }
  #month_calendar .day_cell .all_day {
    margin-bottom: 2px;
    background-color: #f2f2f2;
    border: 1px solid;
    border-color: #c6c6c6; }
  #month_calendar .day_cell .multi_day {
    margin-bottom: 2px;
    background-color: #f2f2f2;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-left: none;
    border-right: none;
    border-color: #c6c6c6;
    margin-left: -5px;
    margin-right: -5px; }
  #month_calendar .day_cell .all_day a,
  #month_calendar .day_cell .multi_day a {
    color: #565656;
    text-decoration: none; }
  #month_calendar .day_cell .all_day a:hover,
  #month_calendar .day_cell .multi_day a:hover {
    color: #565656;
    text-decoration: underline; }
  #month_calendar .first_day {
    margin-left: 0 !important;
    border-left: 1px solid !important; }
  #month_calendar .last_day {
    margin-right: 0 !important;
    border-right: 1px solid !important; }
  #month_calendar .day_cell .date {
    width: 100%;
    text-align: right;
    margin: 0;
    padding: 0 0 3px; }
  #month_calendar .day_cell .date a {
    font-weight: normal;
    color: #000000;
    text-decoration: none; }
  #month_calendar .day_cell .has_events a {
    color: #d75452;
    font-weight: bold; }
  #month_calendar .day_cell .date .num {
    font-size: 15px;
    color: #8a8a8a; }
  #month_calendar .day_cell .date a:hover {
    color: #000000;
    text-decoration: underline; }
  #month_calendar .day_cell .has_events a:hover {
    color: #000000; }
  #month_calendar .day_cell hr {
    margin-top: 7px;
    margin-bottom: 7px; }
  #month_calendar td.day_cell {
    padding-top: 30px; }
  #month_calendar .out_of_range {
    background: #fbfbfb; }
  #month_calendar .out_of_range .date {
    color: #aeaeae; }
  #month_calendar td.today {
    background-color: #fcf8e4; }
  #month_calendar #days_of_week th {
    padding: 5px 0;
    text-align: center; }
  #month_calendar #month_year th {
    padding: 5px;
    color: #000; }
  #month_calendar #month_year th.left {
    text-align: left; }
  #month_calendar #month_year th.center {
    text-align: center; }
  #month_calendar #month_year th.right {
    text-align: right; }
  #month_calendar .calendar-color {
    background-color: #9f9f9f;
    position: relative;
    top: 0;
    display: inline-block;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    text-indent: -9000px; }

.popover-header span.event_time {
  display: block;
  color: #4f4f4f;
  font-size: 85%;
  margin-top: 5px; }

/* WEEK CALENDAR */
#week_calendar .header div {
  float: left;
  width: 20%; }

#week_calendar .header div.left {
  padding-left: 10px; }

#week_calendar .header div.center {
  width: 55%;
  text-align: center; }

#week_calendar .header div.right {
  text-align: right;
  padding-right: 10px; }

#week_calendar ul.list-group {
  margin-bottom: 0; }

/* DAY CALENDAR */
#day_calendar .header div {
  float: left;
  width: 15%; }

#day_calendar .header div.left {
  padding-left: 10px; }

#day_calendar .header div.center {
  width: 65%;
  text-align: center; }

#day_calendar .header div.right {
  text-align: right;
  padding-right: 10px; }

#day_calendar ul.list-group {
  margin-bottom: 0; }

.list-group-item .calendar_event_title {
  color: #3a3a3a;
  text-decoration: underline; }

.list-group-item .calendar_event_title:hover {
  color: #4f4f4f;
  text-decoration: none; }

.row {
  display: flex;
  flex-wrap: wrap; }

.col-3 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 5px;
  padding-left: 5px;
  flex: 0 0 25%;
  max-width: 25%; }

.col-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 5px;
  padding-left: 5px;
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.justify-content-between {
  justify-content: space-between !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }

.btn {
  margin: 0; }
  .btn svg {
    fill: #6c757d;
    width: 1em; }
  .btn:hover svg {
    fill: #fff; }

.float-right {
  float: right !important; }

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px; }

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6; }

.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }

.popover-header span.event_time {
  display: block;
  color: #4f4f4f;
  font-size: 85%;
  margin-top: 5px; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }
